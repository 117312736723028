import React from 'react'
import TestCreate from '../components/TestBatch/TestCreate'

const TestPostPage = () => {
  return (
    <div>
        <TestCreate/>
    </div>
  )
}

export default TestPostPage