import React from 'react'
import { Link } from 'react-router-dom'
const GetAllTestItems = ({status, item}) => {
  

  return (
    <div>
        <div className='bg-black pr-5 sm:pl-0 pl-4 sm:pr-5 absolute left-6 top-7 justify-center rounded-lg  p-1'>
            <h1 className='sm:text-[30px] text-[20px]  text-white font-bold sm:ml-4'>{item.testTitle}</h1>
          </div>
          <div className='sm:flex justify-start block'>
        {status ? (
          <Link target="_blank" to={item.calink}>
              <button className='hover:bg-yellow-300 lg:w-[300px] lg:mt-7 duration-300 w-[100%] hover:text-fuchsia-700 min-[500px]:mt-[25px] mx-auto text-yellow-200 h-[50px] block  bg-transparent border-solid border-2 border-yellow-300  justify-end rounded-lg  sm:mt-0 mt-5 text-lg sm:m-4 m-0  font-bold '>Download</button>
            </Link>
        ):(
          <div>
            <Link target="_blank" to={item.driveLink}>
              <button className='hover:bg-yellow-300 lg:w-[300px] lg:mt-7 duration-300 w-[100%] hover:text-fuchsia-700 min-[500px]:mt-[25px] mx-auto text-yellow-200 h-[50px] block  bg-transparent border-solid border-2 border-yellow-300  justify-end rounded-lg  sm:mt-0 mt-5 text-lg sm:m-4 m-0  font-bold '>Download Question</button>
            </Link>
            <Link to={item.answerKey} target="_blank">
              <button className='hover:bg-white lg:w-[300px] lg:mt-7 duration-300 w-[100%] hover:text-purple-500 min-[500px]:mt-[25px] mx-auto text-white h-[50px] block  bg-transparent border-solid border-2 border-white justify-end rounded-lg  sm:mt-0 mt-5 text-lg sm:m-4 m-0  font-bold '>Download AnswerKey</button>
            </Link>
            </div>
        )}
          </div>
    </div>
  )
}

export default GetAllTestItems