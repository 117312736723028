import React, { useContext, useEffect, useState } from 'react'
import DataContext from '../../util/DataContext'
import axios from 'axios'
import QuizTestDataDetails from './QuizTestDataDetails'
import SyncLoader from "react-spinners/SyncLoader"


const QuizTestData = ({userData}) => {
  const {data} = useContext(DataContext)
  const purchasedData = userData.purchasedProducts;
  const [productData, setProductData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  // Product Get by user Purchases product
  const productId = data.map((item)=> (item._id))
  const purchasedIds = purchasedData.filter(element => productId.includes(element));

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = process.env.REACT_APP_BACKENDURL + "/testbatch";
        const responses = await Promise.all(
          purchasedIds.map(id => axios.get(`${apiUrl}/${id}`))
        );
        const data = responses.map(response => response.data);
        setProductData(data);
        if(data?.length > 0 ){
          setIsLoading(false)
        }
     
      } catch (error) {
        console.log("error", error);
      }
    };
  
    if (productData.length === 0) {
      fetchData();
    }
  }, [productData, purchasedIds]); // Include fetchData and purchasedIds in the dependency array
  

  return (
    <>
    <div className = "sm:relative mb-0 sm:mb-16 pb-[360px] bg-white px-6 pt-10 sm:pb-8 shadow-xl mt-0 sm:mt-[100px]  sm:mx-auto sm:max-w-[800px] max-w-[100%] sm:rounded-lg sm:px-10">
    <div>
    <h1 className='text-center font-bold p-5 underline font-Roboto sm:text-3xl text-xl'>ONLINE QUIZ TEST</h1>
    <div className='text-center justify-center mt-5'>
    {isLoading ?<SyncLoader color="#0084FF" /> : ""}
    </div>
    {productData.map((item) => (
      <QuizTestDataDetails
      key= {item._id}
      data = {item}
      />
    ))}
    
    </div>
    </div>
    </>
  )
}

export default QuizTestData