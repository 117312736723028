import React, { useEffect, useState } from 'react'
import CardData from './CardData.js';
import SyncLoader from "react-spinners/SyncLoader"
import axios from 'axios';
const Cards = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true)

  useEffect(()=> {
    if(data.length === 0){
      fetchData()
    }
  },[data])
 
    const fetchData = async() => {
      try{
        const response = await axios.get(process.env.REACT_APP_BACKENDURL+'/ca')

        if(response){
          setData(response.data.reverse())
          setIsLoading(false)
        }
        else{
          console.log("errord da")
        }
      }catch(error){
        console.log("network Error ", error)
      }
    }

  // useEffect(()=> {
  //   const fetchData = async() => {

     
  //     const response = await fetch("https://tnpscupdate-48799-default-rtdb.firebaseio.com/.json");
  //     const responseData = await response.json();
      
  //     const loadedData =  [];

  //     for(const key in responseData) {
  //       loadedData.push({
  //         id: key,
  //         imgurl: responseData[key].imgurl,
  //         title: responseData[key].title,
  //         date: responseData[key].date,
  //         description: responseData[key].description
          
  //       })
  //     }
  //     setData(loadedData);
  //     setIsLoading(false);
  //     <CardDetailsPage data={data}/>
  //   }
  //   fetchData();
    
  // },[data])
  
  return (
    <div>
     
    <div className='sm:m-14 justify-start rounded-lg m-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5'>
   {data.map((item) => (
    <CardData 
      key= {item._id}
      id={item._id}
      imgUrl={item.imgUrl}
      title={item.title}
      date= {item.date}
      description={item.description}
    />
  ))}
 
    </div>
    <div className='text-center justify-center'>
    {isLoading ?<SyncLoader color="#0084FF" /> : ""}
    </div>
    </div>
  )
}
export default Cards;