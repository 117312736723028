import { useRef, useState } from "react"
import JoditEditor from "jodit-react"
import { errorToast, successToast, warningToast } from "../../util/toaster"



const AddCards = () => {
    const [enteredImgUrl, setEnteredImgUrl] = useState("")
    const [enteredTitle, setEnteredTitle] = useState("")
    const [enteredDate, setEnteredDate] = useState("")
    const [enteredDescription, setEnteredDescription] = useState("")
    const [text, setText] = useState("")

    const editor = useRef(null)
    

    const submitHandler = async(event) => {
        event.preventDefault()
        

        const newCard = {
            key:"_id",       
            _id: Math.random().toString(),
            imgUrl: enteredImgUrl,
            title: enteredTitle,
            date: enteredDate,
            description: enteredDescription
        }
       const postData =  {
        method: "POST",
        headers: {
            'Content-Type' : 'application/json'
        },
        body: JSON.stringify(newCard)

       }
       try{
        const response =await fetch(process.env.REACT_APP_BACKENDURL+"/ca",
       postData)

       const resData = await response.json()
       if(!response.ok){
        warningToast(resData.message)
       }
       else{
        successToast("Posted SUccessfuly")
       }
       }catch(err){
        errorToast("Network Error")
       }
       
    }
    

    const labelClass = 'justify-start font-medium'
    const inputClass = " font-medium border-solid active:border-lime-600 pl-2 mt-2 w-[100%] justify-stretch block border-cyan-500 border-2 rounded-md"
    return(
        <div className="relative bg-white mt-20 px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg sm:px-10">
        <form onSubmit={submitHandler}>
            <label className={labelClass}>Add Image Url</label>
            <input value={enteredImgUrl} onChange={(e)=> setEnteredImgUrl(e.target.value)} className={inputClass} type="text"></input>
            <br></br>
            <label className={labelClass}>Add Title</label>
            <input value={enteredTitle} onChange={(e)=> setEnteredTitle(e.target.value)} className={inputClass} type="text"></input>
            <br></br>
            <label className={labelClass}>Date</label>
            <input value={enteredDate} onChange={(e)=> setEnteredDate(e.target.value)} className={inputClass} type="text" ></input>
            <br></br>
            <label className={labelClass}>Description</label>
            {/* <input
            type="textarea"
            value={enteredDescription} 
            onChange={(e)=> setEnteredDescription(e.target.value)}

            id="my-textarea" name="my-textarea" rows="4" 
            className="mt-1 p-2 block w-full border border-blue-500 rounded-md focus:outline-none focus:border-black"></input> */}
            <JoditEditor
                ref={editor}
                value={enteredDescription}
                onChange={newContent => setEnteredDescription(newContent) }
            />
            
            <button className="bg-pink-600 w-[100%] font-medium hover:bg-pink-700 bg- text-white  p-2 mt-6 rounded-lg">Submit</button>
        </form>
        
        </div>
    ) 
}
export default AddCards