import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DataContext from '../../util/DataContext';

const TestPage = () => {
    const {userData} = useContext(DataContext)
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const { id } = useParams();

    useEffect(() => {
        if (questions.length === 0) {
            getQuestions();
        }
    }, [questions]);

    const getQuestions = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKENDURL}/quizTestQuestions/${id}`);
            setQuestions(response.data);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch questions');
            setLoading(false);
        }
    };

    const handleOptionClick = (option) => {
        const newSelectedOptions = [...selectedOptions];
        newSelectedOptions[currentQuestionIndex] = option;
        setSelectedOptions(newSelectedOptions);
    };

    const postMarks = async() => {
        const listData = {
            name:userData.name,
            totalMarks: getTotalCorrectAnswers(),
            testPaperId:id
        }
        try{
            const response = await axios.post(`${process.env.REACT_APP_BACKENDURL}/quizResults`, listData)
            console.log('Data posted successfully:', response.data);
        }catch(err){
            console.error('Error posting data:', error);
        }
    }

    const handleNextClick = () => {
        if (currentQuestionIndex < questions?.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            postMarks()
            setShowResults(true);
        }
    };

    const handleRestart = () => {
        setCurrentQuestionIndex(0);
        setSelectedOptions([]);
        setShowResults(false);
    };

    const getTotalCorrectAnswers = () => {
        return questions.reduce((total, question, index) => {
            if (selectedOptions[index] === question.answer) {
                return total + 1;
            }
            return total;
        }, 0);
    };

    if (loading) {
        return <div className="text-center mt-20">Loading...</div>;
    }

    if (error) {
        return <div className="text-center mt-20 text-red-500">{error}</div>;
    }

    return (
        <div className="relative bg-white sm:mt-20 sm:mb-20 px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-[1000px] sm:rounded-lg sm:px-10">
            {showResults ? (
                <div>
                    <h2 className="text-2xl font-bold">Results</h2>
                    <p  className="text-blue-500 text-xl mt-4 font-Rahul1">Your Score: {getTotalCorrectAnswers()} / {questions.length} </p>
                    
                    <ul className="mt-4">
                        {questions.map((question, index) => (
                            <li className="font-tamil mt-3" key={question._id}>
                                {`Question No: ${question.questionNo} `}
                                <span dangerouslySetInnerHTML={{ __html: question.enteredQuestion }} />
                                <p className={`mt-2 ${selectedOptions[index] === question.answer ? 'text-green-500' : 'text-red-500'}`}>
                                    Your answer: {selectedOptions[index]}
                                </p>
                                {selectedOptions[index] !== question.answer && (
                                    <p className="text-gray-500">Correct answer: {question.answer}</p>
                                )}
                            </li>
                        ))}
                    </ul>
                    <button onClick={handleRestart} className="mt-5 px-4 py-2 bg-blue-500 text-white rounded">
                        Restart
                    </button>
                </div>
            ) : (
                <div>
                    {questions?.length > 0 && (
                        <>
                            <h2 className="mt-3 text-xl font-bold text-fuchsia-700 ">{`Question No: ${questions[currentQuestionIndex].questionNo}`}</h2>
                            <h2 className="mt-3 text-md sm:text-xl text-red-600 font-tamil font-bold"><span dangerouslySetInnerHTML={{ __html: questions[currentQuestionIndex].enteredQuestion }} /></h2>
                            <ul>
                                {questions[currentQuestionIndex].options.map((option, index) => (
                                    <li
                                        key={index}
                                        onClick={() => handleOptionClick(option)}
                                        className={`mt-5 border-2 text-blue-800 font-tamil rounded-md border-solid border-lime-600 p-2 cursor-pointer ${selectedOptions[currentQuestionIndex] === option ? 'bg-blue-200' : ''}`}
                                    >
                                        {option}
                                    </li>
                                ))}
                            </ul>
                            <button onClick={handleNextClick} className="mt-[30px] px-4 py-2 bg-blue-500 text-white rounded">
                                Next
                            </button>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default TestPage;
