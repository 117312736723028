import React from 'react'
import GetAllTestItems from './GetAllTestItems'

const GetAllTest = ({data, setShowAllTests, status}) => {
    const svg1 = <svg  className="h-6 w-6"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor" >
    <path
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    d="M6 18L18 6M6 6l12 12"/>
    </svg>
    const testListData = (data.testData)
    const closeHandler = () => {
      setShowAllTests(prevValue => !prevValue)
    }
    
  return (
  <> 
  <div className='flex justify-between'>
    <h1 className='text-[30px] font-bold my-4 sm:ml-4 '>{data.title}</h1>
    <button onClick={closeHandler} className='bg-pink-600 mt-5 h-8 hover:bg-pink-500 rounded-md text-white'>{svg1}</button>
  </div>
  
  
    {testListData.map((item)=>(
        <div key={item._id} className='relative mt-6 p-5 shadow-xl drop-shadow-2xl pt-20 bg-gradient-to-r from-fuchsia-600 to-purple-500 rounded-lg mx-auto  max:w-lg'>
          
          <GetAllTestItems status={status} item = {item}/>
        </div>
    ))}
    </>
  )
}

export default GetAllTest