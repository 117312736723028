import React, { useContext } from 'react'
import TestBatchItems from './TestBatchItems'
import SyncLoader from "react-spinners/SyncLoader"
import DataContext from '../../util/DataContext'

const TestData = () => {
    const {data, loader}  = useContext(DataContext)
    
  return (
    <div>
    <div className='sm:m-14 justify-start rounded-lg m-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5'>
    {data.map((items)=> (
        <TestBatchItems 
            key = {items._id}
            id = {items._id}
            title = {items.title}
            image = {items.imageUrl}
            description = {items.description}
            price = {items.price}
            rating = {items.rating}
            drive = {items.drive}
        />
     )) }
     
    </div>
    <div className='text-center justify-center'>
    {loader ?<SyncLoader color="#0084FF" /> : ""}
    </div>
    </div>
  )
}

export default TestData