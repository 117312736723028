import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const SignInSucess = (props) => {
  const navigate = useNavigate()
  const handler = () => {
    navigate("/")
      window.location.reload()
  }
  return (
    <div className="relative bg-white mt-10 px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg sm:px-10">
        <h1 className='text-center text-[20px] font-Roboto font-bold'>Your Account Is Created SuccessFully</h1>
        <Link to="/">
        <button onClick={handler} className="bg-pink-600 w-[100%] font-medium hover:bg-pink-700 bg- text-white  p-2 mt-6 rounded-lg">Next</button>
        </Link>
    </div>
  )
}

export default SignInSucess