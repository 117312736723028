import React from 'react'
import PageContent from '../components/PageContent'
import Footer from '../components/Footer'


const HomePage = () => {

  return (
    <div >
    {/* <h1 className='text-black text-center flex justify-center  mt-20 font-extrabold text-6xl'>Home</h1> */}
    <PageContent>
      <p>Browse all our events</p>
    </PageContent>
  
<Footer/>
    </div>
  )
}

export default HomePage