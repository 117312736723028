import React, { useState } from 'react'
import SelectedTest from './SelectedTest';
import { Link } from 'react-router-dom';

const QuizTestDataDetails = ({data}) => {
  const [showAllTests, setShowAllTests] = useState(false)
 
  if (data.status === "ca") {
    return null;
  }
  if (data.status === "test") {
    return null;
  }
  return (
    <>
    {!showAllTests ? (
      <div className='p-5'>
        <div className='block sm:flex justify-between'>
        <Link onClick={() => setShowAllTests(true)}>
          <h1 className='text-xl sm:text-3xl bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-violet-500 font-bold'>
            {data.title}
          </h1>
          </Link>
          {/* <button
            
            className='bg-pink-600 text-white justify-end rounded-lg hover:bg-pink-500 sm:mt-0 mt-5 text-lg font-bold w-[130px] h-9'
          >
            
          </button> */}
        </div>
        <div className='border-[1px] border-dotted mt-5 border-black'></div>
      </div>
    ) : (
      <SelectedTest setShowAllTests={setShowAllTests} data={data} />
    )}
  </>
  )
}

export default QuizTestDataDetails