import React, { useState } from 'react'
import { Link, useNavigate} from 'react-router-dom'
import ForgotPassword from './ForgotPassword'
import { errorToast, successToast, warningToast } from '../../util/toaster'

  const LoginPage = () => {
  const [forgot, setForgot] = useState(false)
  const labelClass = 'justify-start font-medium'
  const inputClass = " font-medium border-solid active:border-lime-600 pl-2 mt-2 w-[100%] h-9 justify-stretch block border-cyan-500 border-2 rounded-md"
  const navigate = useNavigate()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)

  const forgotPasswordHandler = () => {
    setForgot(!forgot)
  }

  
  const submitHandler = async(e) => {
    setIsSubmitting(true)
    e.preventDefault()

    const newUser = {
      // key : "_id",
      // _id : Math.random().toString,
      // name: name,
      // phone: phoneNo,
      email: email,
      password:password,
      // confirmPassword:confirmPassword
    }
    const postUser = {
      method: "POST",
      headers: { "content-type" : "application/json"},
      body: JSON.stringify(newUser)
    }
    try{
      const response = await fetch(process.env.REACT_APP_BACKENDURL+"/user/login", postUser)

    const resData = await response.json();
    const token = resData.token;
    
    if(!response.ok){
      warningToast(resData.message)
      setIsSubmitting(false)
    }
    else{
      successToast(resData.message)
      localStorage.setItem('token', token);
      navigate("/")
      window.location.reload()
    }
    }catch(err){
      errorToast("Network Error",err)
    }
    
  }
  return ( 
    <>
    {!forgot? 
    <div className="sm:relative bg-white sm:mt-20 px-6 pt-10 sm:pb-8 pb-[130px] shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg  sm:rounded-lg sm:px-10">
      
    <form onSubmit={submitHandler}>
        <h1 className='text-center sm:mt-2 mt-20 mb-3 text-2xl  font-bold text-blue-600'>TNPSC UPDATES</h1>
        <h3 className='text-center font-Roboto text-[30px]'>Login </h3>
            <label className={labelClass}>Email</label>
            <input onChange={(e)=>{setEmail(e.target.value)}} className={inputClass} type="text"></input>
            <br></br>
            <label className={labelClass}>Password</label>
            <input onChange={(e)=>{setPassword(e.target.value)}} className={inputClass} type="text"></input>
            <br></br>

            <button  className="bg-pink-600 w-[100%] font-medium hover:bg-pink-700 bg- text-white  p-2 mt-6 rounded-lg">{isSubmitting? "Submitting...": "Submit"}</button>
            <ul className="flex  mt-5 text-center justify-center space-x-3">
            <Link to="">
            <li onClick={forgotPasswordHandler} className='text-indigo-400 font-bold'>Forgot Password</li>
            </Link>
            <h2>or  </h2>
            <Link to="/register">
            <li className='text-indigo-400 font-bold'>Create Account</li>
            </Link>
            </ul>
        </form> 
        </div>
        : <ForgotPassword/>}
        
        </>
  )
}

export default LoginPage