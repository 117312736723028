import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

  const QuizResults = () => {

    const {id} = useParams()
    const [resultData, setResultData] = useState([]);

    useEffect(()=>{
      if(resultData.length === 0) {
        fetchResultById()
      }
    },[resultData])

    const fetchResultById = async() => {
      try{
        const response = await axios.get(`${process.env.REACT_APP_BACKENDURL}/quizResults/${id}`)
        setResultData(response.data)
      }catch(err){
        console.log(err)
      }
    }

    const sortedResultData = [...resultData].sort((a, b) => b.totalMarks - a.totalMarks);

    const ranks = [];
    let currentRank = 1;
    let previousMarks = sortedResultData[0]?.totalMarks;
    let rankOffset = 0;
    
    sortedResultData.forEach((item, index) => {
      if (index > 0) {
        if (item.totalMarks < previousMarks) {
          currentRank = index + 1 - rankOffset;
        } else {
          rankOffset++;
        }
        previousMarks = item.totalMarks;
      }
      ranks[index] = currentRank;
    });
    
  return (
    <>
    <div className="relative  bg-white sm:mt-20 sm:mb-20 px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-[1000px] sm:rounded-lg sm:px-10">
   {resultData.length > 0 ?
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Results</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full border-collapse border border-transparent rounded-lg bg-gradient-to-r from-blue-gradient-start to-blue-gradient-end">
          <thead>
            <tr>
              <th className="border border-gray-200 px-4 py-2 bg-blue-500 text-white">Rank</th>
              <th className="border border-gray-200 px-4 py-2 bg-blue-500 text-white">Name</th>
              <th className="border border-gray-200 px-4 py-2 bg-blue-500 text-white">Total Marks</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {sortedResultData.map((item, index) => (
              <tr key={index} className="hover:bg-blue-100">
                <td className="border border-gray-200 px-4 py-2">{ranks[index]}</td>
                <td className="border border-gray-200 px-4 py-2">{item.name}</td>
                <td className="border border-gray-200 px-4 py-2">{item.totalMarks}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
 :
 <h1 className='text-center'>Loading ....</h1>
  }
    <Link to={"/quiz"}>
    <button className="mt-[30px] flex justify-end px-4 py-2 bg-blue-500 text-white rounded">Back</button>
    </Link>
        </div>    
        </>
  )
}

export default QuizResults