import React from 'react'
import { Link } from 'react-router-dom'
const CardData = (props) => {
  

  const desSlice = props.description.slice(0,70)
  // let desSlice;
  // props.title.length >= 9 ? desSlice = des.slice(0,65): desSlice = des.slice(0,95)
  
  const scrollHandler = () => {
    setTimeout(()=> {
      window.scrollTo(0, 0);
    },480)
  }
  return (
    <>
        <div className='h-[100%] font-tamil shadow-xl drop-shadow-2xl rounded-lg bg-gradient-to-r from-sky-500 to-indigo-500'>
          <div className='rounded-xl relative'>
              <img className='w-[100%] rounded-t-lg h-[220px]' alt= {props.title} src={props.imgUrl}/>
                <div className=' rounded-l w-[132px] bg-black top-0  justify-end absolute h-[38px]'></div>
                  <p className='pt-1 left-3 drop-shadow-[2px_2px_rgba(0,0,0,1)] top-0 absolute text-white pr-3 font-bold text-xl'>{props.date}</p>
          </div>
              <div className='px-5 text-white'>
                <div className='block justify-between '>
                  <h5 className='pt-4 text-xl mono font-bold'>{props.title}</h5>
          </div>
                  <p className='text-gray-200'><span dangerouslySetInnerHTML={{ __html: desSlice}} /></p>
                      <Link to={props.id}>
                        <button onClick={scrollHandler} className='w-[150px] hover:w-[160px] delay-150 h-[50px] block rounded-lg text-lg hover:bg-pink-500 my-5 bg-pink-600'>
                         Learn More
                         </button>
                      </Link>
          </div>
      </div>
      </>
            )
          }
          export default CardData