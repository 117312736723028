// "use client"
import React, {  useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'
import DataContext from '../util/DataContext'

const MainNavigation = () => {
    const {userData, logout, token} = useContext(DataContext)
    const [isClicked, setIsClicked] = useState(false)
    
    const toggleNavbar = () => {
        setIsClicked(!isClicked)
    }
        const svg1 = <svg  className="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor" >
            <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"/>
        </svg>
        const svg2 = ( <svg  className="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 6h16M4 12h16m-7 6h7"/>
        </svg>)
        const dropDown = <svg class="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
      </svg>
        let isAdmin;
    if(token && userData.role === "admin"){
        isAdmin = true
    }
    
  return (
    <>
        <nav  className='bg-gradient-to-r from-sky-500 to-indigo-500'>
        
            <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
                <div className='flex items-center justify-between h-16'>
                    <div className='flex items-center'>
                        <div className='flex-shrink-0'>
                         <NavLink to="/" className='text-white text-xl font-bold'>TNPSC UPDATES</NavLink>   
                        </div>
                    </div>
                    <div className='hidden md:block'>
                        <div className='font-semibold ml-4 flex items-center space-x-4'>
                        
                            <NavLink to="/" className='text-white hover:bg-white hover:text-black rounded-lg p-2'>Home</NavLink>  
                            
                            <NavLink to="/currentaffairs" className='text-white hover:bg-white hover:text-black rounded-lg p-2'>Currentaffairs</NavLink>  
                        
                            <NavLink to="/testbatch" className='text-white hover:bg-white hover:text-black rounded-lg p-2'>Testbatch & CA</NavLink>
                            <NavLink to="/quiz" className='text-white hover:bg-white hover:text-black rounded-lg p-2'>Quiz</NavLink>
                            {isAdmin? 
                            <NavLink to="/cards" className='text-white hover:bg-white hover:text-black rounded-lg p-2'>Add CA</NavLink>:
                            <NavLink to="/yourpurchases" className='text-white hover:bg-white hover:text-black rounded-lg p-2'>Your Purchases</NavLink>
                             }
                             {isAdmin? <NavLink  to="/test" className='text-white hover:bg-white hover:text-black rounded-lg p-2'>Add Test</NavLink>: null}
                            <NavLink to="/login" className='text-white hover:bg-white hover:text-black rounded-lg p-2'>{token?`${userData.name? `${userData.name}` : "Loading..."}` : logout}</NavLink>
                        </div>
                    </div>
                    <div className='md:hidden flex items-center'>
                        <button className='inline-flex duration-500 items-center justify-center p-2 rounded-md text-white 
                        hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white' onClick={toggleNavbar}>
                        {isClicked ? svg1 : svg2}
                        </button>
                    </div>
                </div>
            </div>
        {isClicked && (
            <div className='md:hidden '>
                <div className='px-2 pt-2 pb-3  space-y-1 sm:px-3 duration-300'>
                <NavLink onClick={toggleNavbar} to="/" className='text-white block hover:bg-white hover:text-black rounded-lg p-2'>Home</NavLink>  
                            
                            <NavLink onClick={toggleNavbar} to="/currentaffairs" className='text-white block hover:bg-white hover:text-black rounded-lg p-2'>Currentaffairs</NavLink>  
                        
                            <NavLink onClick={toggleNavbar} to="/testbatch" className='text-white block hover:bg-white hover:text-black rounded-lg p-2'>Testbatch & CA</NavLink>
                            <NavLink onClick={toggleNavbar} to="/quiz" className='text-white block hover:bg-white hover:text-black rounded-lg p-2'>Quiz</NavLink>
                            {isAdmin? 
                            <NavLink onClick={toggleNavbar} to="/cards" className='text-white block hover:bg-white hover:text-black rounded-lg p-2'>Add CA</NavLink>:
                            <NavLink onClick={toggleNavbar} to="/yourpurchases" className='text-white block hover:bg-white hover:text-black rounded-lg p-2'>Your Purchases</NavLink>
                             }
                             {isAdmin? <NavLink to="/test" onClick={toggleNavbar} className='text-white block hover:bg-white hover:text-black rounded-lg p-2'>Add Test</NavLink>: null}
                            <NavLink onClick={toggleNavbar} to="/login"  className='text-white  block hover:bg-white hover:text-black rounded-lg p-2'>{token?`${userData.name? `${userData.name}` : "Loading..."}` : logout}</NavLink>
                </div>
            </div>
        )}
        </nav>
    </>
  )
}
export default MainNavigation;