import React from 'react'
import SelectTest from '../components/Quiz/SelectTest'


const Quiz = () => {
  return (
    <div>
        <SelectTest/>
    </div>
  )
}

export default Quiz