import React from 'react'
import TestBatchDesign from './HomeDesigns.js/TestBatchDesign';

const PageContent = ({title, Children}) => {
  return (
    <div className=''>
        <h1 className='text-black text-center flex justify-center mt-20 font-extrabold text-6xl'>{title}</h1>
        {Children}
        <TestBatchDesign/>
    </div>
  )
}
export default PageContent;