import React, { createContext, useEffect, useState } from "react"
import { getAuthToken } from "./auth"
import { jwtDecode } from "jwt-decode"
import axios from "axios"

const DataContext = createContext({})

export const DataProvider = ({children}) => {
    const [userData, setUserData] = useState([])
    const [logout, setLogout] = useState("Login")
    const token = getAuthToken()
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(true)

    useEffect(()=>{
        if(data.length === 0){
            getTestBatchData()
        }
    },[data])
    const getTestBatchData = async() => {
       await axios.get(process.env.REACT_APP_BACKENDURL+"/testbatch")
        
        .then((response) => {
            setData(response.data.data.reverse())
            setLoader(false)
        }).catch((error) => {
            console.log(error)
        })
    }
        useEffect(()=> {
            const fetchData = async() => {
            const decodeToken = jwtDecode(localStorage.getItem("token"))
                try{
                    const response = await fetch(process.env.REACT_APP_BACKENDURL+"/user/"+ decodeToken.id)
                    const resData = await response.json()
                    if(!response.ok){
                        console.log("error")
                    }
                    else{
                        setUserData(resData)
                    }
                }catch(err){
                    console.log("Network Error")
                }
            }
            if(token && userData.length === 0){
                fetchData()
                
            }else{
                setLogout("Login")
            }
        },[token, userData])
        
    return(
        <DataContext.Provider value={{
            userData, logout, token, loader, data
        }} >
        {children}
        </DataContext.Provider>
    )
}
export default DataContext