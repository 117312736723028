import React from 'react'
import { Link } from 'react-router-dom'

const CaItem = ({item}) => {
  

  return (
    <div className='sm:mt-[100px]'>
        <div className='relative bg-white px-6 pt-10 pb-8 shadow-xl  sm:mx-auto sm:max-w-[1000px] mb-[0px] sm:mb-[50px] sm:rounded-lg sm:px-10'>
        <h4 className='absolute left-[67%] top-14 font-Roboto  sm:left-[85%]'>{item.date}</h4>
          <Link to="/currentaffairs">
            <button className='bg-yellow-400 hover:bg-yellow-200 m-1 text-lg w-[100px] sm:w-[140px] font-Rahul1  sm:m-3 h-[40px] rounded-md'>back</button> 
          </Link>
        <div className=' '>    
            <div className='m-1 mt-5 sm:m-3 sm:mt-10'>
                <img className='rounded-xl ' alt={item.title} src={item.imgUrl}></img>
            </div>
            <div className='sm:ml-10  ml-2 text-left '>
                <h1 className='text-[30px] font-black font-tamil  decoration-amber-500 m-1 mt-10 mb-5 sm:m-3 sm:mt-10  '>{item.title}</h1>
                <div className='sm:flex block'>
                </div>
                <h2 className='text-lg  text-gray-500 m-1 sm:m-3 font-tamil font-regular '><span dangerouslySetInnerHTML={{ __html: item.description }} /></h2>
            </div>
        </div>
    
        
    </div>
</div>
  )
}

export default CaItem
