import React,{useState} from 'react'
import Emailres from '../Responses/Emailres'
import { errorToast, successToast } from '../../util/toaster'

const ForgotPassword = () => {
    const [resetLink, setResetLink] = useState(false)
    
    const labelClass = 'justify-start text-pink-600 font-medium'
    const inputClass = " font-medium border-solid active:border-lime-600 pl-2 mt-2 w-[100%] h-9 justify-stretch block border-gray-600 border-2 "  
    const [email, setEmail] = useState("")
    

    const submitHandler = async(e) => {
        e.preventDefault()
        const sendEmail = {
            email: email
        }
        const postEmail = {
            method: "POST",
            headers: {"content-type": "application/json"},
            body: JSON.stringify(sendEmail)
        }
        const response = await fetch(process.env.REACT_APP_BACKENDURL+"/user/forgotPassword", postEmail)
        
        if(response){
            successToast("Check Your Email, if not Recieved Check spam Messages")
            setResetLink(!resetLink)
        }
        else{
            errorToast("Error")
        }
    } 

    return(
    <>
        {!resetLink? 
        <div className="relative  bg-white sm:mt-10 py-[200px] px-6 sm:pt-10  sm:pb-8 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg sm:px-10">
            <h1 className='text-center  text-bold text-[20px] mb-5'>Forgot Password</h1>
                <form onSubmit={submitHandler}>
                    <label className={labelClass}>Enter Your Email</label>
                    <input onChange={(e)=>{setEmail(e.target.value)}}className={inputClass} type="text"></input>
                    <br></br>
                    <button className="bg-pink-600 w-[100%] font-medium hover:bg-pink-700 bg- text-white  p-2 mt-6 rounded-lg">Submit</button>
                </form>
        </div>
        :<Emailres/>}
     </>
    )
}

export default ForgotPassword