import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams} from 'react-router-dom'
import { successToast, errorToast, warningToast  } from '../../util/toaster'
import JoditEditor from 'jodit-react'

  const CreateQuiz = () => {

  const labelClass = 'justify-start font-medium'
  const inputClass = " font-medium border-solid active:border-lime-600 pl-2 mt-2 w-[100%] h-9 justify-stretch block border-cyan-500 border-2 rounded-md"
  const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [questionNo, setQuestionNo] = useState("")
  const [formData, setFormData] = useState({
    questionNo: "",
    optionA: "",
    optionB: "",
    optionC: "",
    optionD:"",
    enteredQuestion:"",
    answer:""
  });

  const [error, setError] = useState('');
  const {id} = useParams()
  

  const submitHandler = async(e) => {
    setIsSubmitting(true)
    e.preventDefault()

    const listData = {
    testPaperId: id,
    options:[formData.optionA, formData.optionB, formData.optionC, formData.optionD,],
    enteredQuestion:formData.enteredQuestion,
    questionNo:questionNo,
    answer:formData.answer
    }
 
    const postUser = {
      method: "POST",
      headers: { "content-type" : "application/json"},
      body: JSON.stringify(listData)
    }
   
    try{
      const response = await fetch(process.env.REACT_APP_BACKENDURL+"/quizTestQuestions", postUser)
      const resData = await response.json();
    
    if(response.ok){
      successToast(resData.message)
      setIsSubmitting(false)
      setFormData({
        questionNo: "",
        optionA: "",
        optionB: "",
        optionC: "",
        optionD:"",
        enteredQuestion:"",
        answer:""
      })
      setQuestionNo("")
    }
    else{
      warningToast(resData.message)
      setIsSubmitting(false)
    }
    }catch(err){
      errorToast("Network Error",err)
    }   

  }
  const editor = useRef(null);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  return (
    <>
    <div className="relative  bg-white sm:mt-20 sm:mb-20 px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-[1000px] sm:rounded-lg sm:px-10">
        <form onSubmit={submitHandler}>
        <h1 className='text-center mb-10 font-bold text-[30px]'>Create Questions</h1>
        <div className='sm:grid sm:grid-cols-1'>
          <div className='mr-8'>
            <label className={labelClass}>Question No</label>
            <input value={questionNo} onChange={(e)=> setQuestionNo(e.target.value)} className={inputClass} type="number"></input>
            <br></br>
            </div>
            <div className='mr-8'>
            <label className={labelClass}>Question</label>
            <JoditEditor
                ref={editor}
                value={formData.enteredQuestion}
                onChange={newContent => setFormData({enteredQuestion:newContent}) }
            />
            <br></br>
            </div>
            
            <div className='mr-8'>
            <label className={labelClass}>Option A</label>
            <input value={formData.optionA} name="optionA" onChange={handleChange}  className={inputClass} type="text"></input>
            <br></br>
            </div>
            
            <div className='mr-8'>
            <label className={labelClass}>Option B</label>
            <input value={formData.optionB} name="optionB" onChange={handleChange} className={inputClass} type="text"></input>
            <br></br>
            </div>

            <div className='mr-8'>
            <label className={labelClass}>Option C</label>
            <input value={formData.optionC} name="optionC" onChange={handleChange}  className={inputClass} type="text"></input>
            <br></br>
            </div>

            <div className='mr-8'>
            <label className={labelClass}>Option D</label>
            <input value={formData.optionD} name="optionD" onChange={handleChange}  className={inputClass} type="text"></input>
            <br></br>
            </div>

            <div className='mr-8'>
            <label className={labelClass}>Answer</label>
            <input value={formData.answer} name="answer" onChange={handleChange}  className={inputClass} type="text"></input>
            <br></br>
            </div>

            <div className='mr-8'>
            
            <button className="bg-pink-600 w-[100%] font-medium hover:bg-pink-700 bg- text-white  p-2 mt-6 rounded-lg">{isSubmitting? "Submitting..." : "Submit"}</button>
            </div>
            </div>
            
        </form>
        </div>
       
        </>
  )
}

export default CreateQuiz