import React from 'react'
import { Link } from 'react-router-dom'

const TestBatchItems = (props) => {
  const des = props.description
  let desSlice;
  props.title.length >= 5 ? desSlice = des.slice(0,65): desSlice = des.slice(0,95)

  const scrollHandler = () => {
    setTimeout(()=> {
      window.scrollTo(0, 0);
    },480)
  }
  return (
    <>

    <div className='h-[100%] shadow-xl drop-shadow-2xl rounded-lg bg-gradient-to-r from-sky-500 to-indigo-500'>
      <div className='rounded-xl relative'>
                <img className='w-[100%]  rounded-t-lg h-[220px]' alt= {props.title} src={props.image}/>
                <div className='bg-black rounded-l w-[90px] top-0 right-0 justify-end absolute h-[40px]'></div>
                <p className='pt-1 right-2 drop-shadow-[2px_2px_rgba(0,0,0,1)] top-0 absolute text-white pr-3 font-bold text-xl'>₹ {props.price}</p>
          </div>
          <div className='px-5 text-white'>
              <div className=' block justify-between '>
                  <h2 className='pt-4 text-xl mono font-bold'>{props.title}</h2>
              </div>
                  <p className='text-gray-200'><span dangerouslySetInnerHTML={{ __html: desSlice}} /></p>
                      <Link to={props.id}>
                        <button onClick={scrollHandler} className='w-[150px]  h-[50px] block rounded-lg text-lg hover:bg-pink-700 my-5 bg-pink-600'>
                         More Details
                         </button>
                      </Link>
          </div>
      </div>

    </> )
}

export default TestBatchItems