import React from 'react'
import RegisterPage from '../components/login/RegisterPage'

const CreateAc = () => {
  return (
    <div>
        <RegisterPage/>
    </div>
  )
}

export default CreateAc