import { useRouteError } from "react-router-dom";
import MainNavigation from "../components/MainNavigation";


import React from 'react'
import PageContent from "../components/PageContent";

const ErrorPage = () => {
    const error = useRouteError();

    let title = "An error Occured!";
    let message = "Something Went Wrong"

    if(error.status === 500){
        message = error.data.message;
    }
    if(error.status === 404){
        title = " Page Not Found";
        message = "Could not find resourse or Page"
    }

  return (
    <>
        <MainNavigation/>
        <PageContent title={title}>
            <p>{message}</p>
        </PageContent>
    </>
  )
}

export default ErrorPage;