import React from 'react'
import LoginPage from '../components/login/LoginPage'

import LogoutedPage from '../components/login/LogoutedPage'
import { getAuthToken } from '../util/auth'
// import RegisterPage from '../components/login/RegisterPage'

const Auth = () => {
  const token = getAuthToken()
  
  return (
    <div>
    {token? <LogoutedPage/>: <LoginPage/>}
    </div>
  )
}

export default Auth