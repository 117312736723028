import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import SignInSucess from '../Responses/SignInSucess'
import { errorToast, successToast, warningToast } from '../../util/toaster'
const RegisterPage = () => {
  const [signupSuccess, setSignupSuccess] = useState(false)
  const [name, setName] = useState("")
  const [phoneNo, setPhoneNo] = useState("")
  const [error, setError] = useState('');
  const [touchedConfirmPassword, setTouchedConfirmPassword] = useState(false);
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const labelClass = 'justify-start font-medium'
  const inputClass = " font-medium border-solid active:border-lime-600 pl-2 mt-2 w-[100%] h-9 justify-stretch block border-cyan-500 border-2 rounded-md"
  const [isSubmitting, setIsSubmitting] = useState(false)
  
  // Toaster 


  const CloseButton = () => {
    return (
      <span className="mt-3 mr-4 text-lg font-bold" role="button">
        x
      </span>
    );
  };

 


  const submitHandler = async (event) => {
    setIsSubmitting(true)
    event.preventDefault()

    const newUser = {
      key : "_id",
      _id : Math.random().toString,
      name: name,
      phone: phoneNo,
      email: email,
      password:password,
      confirmPassword:confirmPassword
    }
    const postUser = {
      method: "POST",
      headers: { "content-type" : "application/json"},
      body: JSON.stringify(newUser)
    }
    try{
      const response = await fetch(process.env.REACT_APP_BACKENDURL+"/user/signup", postUser)
    const resData = await response.json()
    const token = resData.token
    const message = resData.message
    if(!response.ok){
      warningToast(message)
      setIsSubmitting(false)
    }
    else{
      successToast(message)
      localStorage.setItem('token', token)
      setSignupSuccess(!signupSuccess)
    }
    }catch(err){
      errorToast(err)
    }
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (touchedConfirmPassword && confirmPassword !== e.target.value) {
        setError("Passwords Not match");
    } else {
        setError('');
    }
};

const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setTouchedConfirmPassword(true);
    if(password.length <8 ){
      setError("Minimum 8 Characters");
    } 
    else if (password !== e.target.value) {
        setError("Passwords Not match");
    }
    
    else {
        setError('');
    }
};

  return (
    <>
    {!signupSuccess? 
    <div className="relative  bg-white sm:mt-20 sm:mb-20 px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-[1000px] sm:rounded-lg sm:px-10">
        <form onSubmit={submitHandler}>
        <h1 className='text-center mb-10 font-bold text-[30px]'>Create Account</h1>
        <div className='sm:grid sm:grid-cols-2'>
          <div className='mr-8'>
            <label className={labelClass}>Name</label>
            <input onChange={(e)=> {setName(e.target.value)}} className={inputClass} type="text"></input>
            <br></br>
            </div>
            <div className='mr-8'>
            <label className={labelClass}>Email</label>
            <input onChange={(e)=>{setEmail(e.target.value)}} className={inputClass} type="text"></input>
            <br></br>
            </div>
            
            <div className='mr-8'>
                <label className={labelClass}>Password</label>
                <input onChange={handlePasswordChange} className={inputClass} type="password"></input>
                <br />
                
            </div>
            <div className='mr-8'>
                <label className={labelClass}>Confirm Password</label>
                <input onChange={handleConfirmPasswordChange} className={inputClass} type="password"></input>
                <br />
                {error && <div className='pb-2 text-rose-500'>{error}</div>}
            </div>
            
        
            <div className='mr-8'>
            <label className={labelClass}>Phone No</label>
            <input onChange={(e)=> {setPhoneNo(e.target.value)}} className={inputClass} type="number"></input>
            <br></br>
            </div>
            <div className='mr-8'>
            
            <button className="bg-pink-600 w-[100%] font-medium hover:bg-pink-700 bg- text-white  p-2 mt-6 rounded-lg">{isSubmitting? "Submitting..." : "Submit"}</button>
            </div>
            </div>
            <ul className="flex  mt-5 text-center justify-center space-x-3">
            
            <Link to="/login">
            <li className='text-indigo-400 font-bold'>Already Have Account</li>
            </Link>
            </ul>
        </form>
        </div>
          : <SignInSucess />}
        </>
  )
}

export default RegisterPage