import React, { useState } from 'react'
import GetAllTest from './GetAllTest'

const PurchasedItems = ({data}) => {
  const [showAllTests, setShowAllTests] = useState(false)
  let status_ca;
  
  if(data.status === "ca"){
    status_ca = true
  }
    
  
  return (
    <>
    {!showAllTests?
    <div className='p-5 '>
    <div className='block sm:flex justify-between'>
        <h1 className='text-3xl bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-violet-500 font-bold'>{data.title}</h1>
        <button onClick={()=> setShowAllTests(true)} className='bg-pink-600 text-white justify-end rounded-lg hover:bg-pink-500 sm:mt-0 mt-5 text-lg font-bold w-[130px] h-9'>{status_ca? "Get CA" : "Get Test"}</button>
        </div>
        <div className='border-[1px] border-dotted mt-5 border-black'>
        </div>
    </div>
    : <GetAllTest status={status_ca} setShowAllTests={setShowAllTests} data={data}/>}
    </>
  )
}

export default PurchasedItems