import React from 'react'
import { Link } from 'react-router-dom'
import { removeToken } from '../../util/auth'

const LogoutedPage = () => {
  const logoutHandler = () =>{
    removeToken()
    window.location.reload()
  }
  return (
    <div className="relative bg-white sm:mt-10 px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg sm:px-10">
        <h1 className='text-center text-[20px] font-Roboto font-bold'>Are Your Sure To Logging Out?</h1>
          <div className='sm:grid sm:grid-cols-2 justify-center mt-10  block'>
            <Link to="/">
                <button onClick={logoutHandler} className='border-2 border-blue-300 hover:bg-blue-500 hover:text-white m-3 text-lg w-[140px] text-blue-600 font-Rahul1  sm:ml-12 sm:m-0 h-[40px] rounded-md'>Confirm</button>
            </Link>
            <Link to="/">
            <button className= 'border-2 border-pink-300 text-pink-500 hover:text-white hover:bg-pink-500 m-3 text-lg w-[140px] font-Rahul1 sm:m-0 h-[40px] sm:ml-4 rounded-md'>Stay Login</button>
            </Link>
          </div>     
    </div>
  )
}

export default LogoutedPage