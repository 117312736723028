import React from 'react'
const Footer = () => {
  const date = new Date().getFullYear()
  return (
    <div className=' mt-[230px] text-white font-bold text-center h-[100%] bg-gradient-to-r from-sky-500 to-indigo-500'>
    <div className='p-[30px]'>
      <ul className='block sm:m-5 justify-center sm:flex leading-[30px] sm:space-x-5'> 
      
      <li><a href='http://fb.com/tnpsc.updates1'>Facebook</a></li>
      <li><a href='https://t.me/tnpsc_updates'>Telegram</a></li>
      <li><a href='https://instagram.com/tnpsc_updates'>Instagram</a></li>
      <li><a href='https://youtu.be/G112y_jMy4g'>Youtube</a></li>
      </ul>
      <h2>CopyRights ©{date}</h2>
    </div>
    </div>
  )
}

export default Footer