import React from 'react'
import { Link } from 'react-router-dom'

const Emailres = () => {
    
  return (
    <div className="relative bg-white mt-10 px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg sm:px-10">
        <h1 className='text-center text-[20px] font-Roboto font-bold'>Your Reset Email Sent SuccessFully... Go to Email and Use the Link to Reset Your Password</h1>
        <p className='text-center mt-10 text-[18px] font-Roboto '>If You Reset Your Password</p>
        <Link to="/login">
        <button onClick={()=> {window.location.reload()}} className="bg-pink-600 w-[100%] font-medium hover:bg-pink-700 bg- text-white  p-2 mt-6 rounded-lg">Click here</button>
        </Link>
    </div>
  )
}

export default Emailres