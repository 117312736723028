import React, {useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { errorToast, successToast, warningToast } from '../../util/toaster'

const ResetPassword = () => {
    const navigate = useNavigate()
    const {resetToken} = useParams()
    const labelClass = 'justify-start text-pink-600 font-medium'
    const inputClass = " font-medium rounded-md border-solid active:border-lime-600 pl-2 mt-2 w-[100%] h-9 justify-stretch block border-gray-600 border-2 "  
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const submitHandler = async(e) => {
        e.preventDefault()
        
        const updatePassword = {
            password: password,
            confirmPassword: confirmPassword
        }
        try{
            const response = await fetch(process.env.REACT_APP_BACKENDURL+"/user/resetPassword/"+resetToken, {
                method:"PATCH",
                headers:{
                    "Content-Type":"application/json"
                },
                body:JSON.stringify(updatePassword)
            })
            const resData =await response.json()
            if(!response.ok){
                warningToast(resData.message)
            }
            else{
                successToast("Password Updated Successfully")
                navigate("/login")
            }
        }catch(err){
            errorToast("Network Error", err)
        }
    }
  return (
    <div className='sm:relative bg-white sm:mt-20 px-6 pt-10 sm:pb-8 pb-[100%] rounded-md shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg  sm:rounded-lg sm:px-10'>
        <h1 className='text-center text-bold text-[20px] mb-5'>Reset Password</h1>
        <form onSubmit={submitHandler}>
        <label className={labelClass}>Set a New Password</label>
            <input onChange={(e)=>{setPassword(e.target.value)}}className={inputClass} type="password"></input>
            <br></br>
            <label className={labelClass}>Confirm a New Password</label>
            <input onChange={(e)=>{setConfirmPassword(e.target.value)}} className={inputClass} type="password"></input>
            <br></br>
            <button className="bg-pink-600 w-[100%] font-medium hover:bg-pink-700 bg- text-white  p-2 mt-6 rounded-lg">Submit</button>
        </form>
    </div>
  )
}

export default ResetPassword