import React from 'react'
import Cards from '../components/Cards/Cards'

const CA = () => {

  return (
    <div>
    <h1 className='text-black text-center flex justify-center mb-10 mt-20 font-extrabold text-4xl sm:text-6xl '>Daily Current Affairs</h1>
    <Cards />
    </div>
  )
  }

export default CA;