import HomePage from './pages/Home';
import { createBrowserRouter,RouterProvider  } from 'react-router-dom';
import ErrorPage from './pages/Error';
import RootLayout from './pages/Root';

import TestBatchPage from './pages/TestBatch';
import CA from './pages/CA';
import CardsPage from "./pages/CardsPage"
import CardDetailsPage, {cardDetailLoader} from './pages/CardDetails';

import CreateAc from './pages/CreateAc';
import TestPostPage from './pages/TestPostPage';
import TestDetailsPage, { testDetailPageLoader } from './pages/TestDetailsPage';
import Auth from './pages/Auth';
import YourPurchases from './components/Purchased/YourPurchases';
import { DataProvider } from './util/DataContext';
import ResetPassword from './components/login/ResetPassword';
import Quiz from './pages/Quiz';
import CreateQuiz from './components/Quiz/CreateQuiz';
import TestPage from './components/Quiz/TestPage';
import QuizResults from './components/Quiz/QuizResults';


const router = createBrowserRouter([{
  path:"/", 
  element:<RootLayout/>,
  errorElement: <ErrorPage/>,
  children:[
    {index: true, element: <HomePage/> },
    {path:"testbatch", element:<TestBatchPage/>},
    {path:"testbatch/:id", element:<TestDetailsPage/>, loader: testDetailPageLoader},
    {path:"yourpurchases", element:<YourPurchases/>},
    {path:"currentaffairs", element:<CA/>},
    {path:'currentaffairs/:id', element:<CardDetailsPage/>, loader: cardDetailLoader},
    {path:'login', element:<Auth/>},
    {path:'register', element:<CreateAc/>},
    {path:'cards', element:<CardsPage/>},
    {path:'test', element: <TestPostPage/> },
    {path:'quiz', element: <Quiz/>},
    {path:"resetpassword/:resetToken", element:<ResetPassword/>},
    {path:"quiz/createQuiz/:id", element:<CreateQuiz/>},
    {path:"quiz/testPage/:id", element:<TestPage/>},
    {path:"quiz/quizResults/:id", element:<QuizResults/>},
  ]}])
function App() {
  return (
    <DataProvider>
    
     <RouterProvider router={router}></RouterProvider>
     </DataProvider>
  );
}

export default App;
