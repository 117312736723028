import React from 'react'
import { Link } from 'react-router-dom'

const NotLoggedIn = () => {
    
  return (
    <div className="absolute sm:inset-5 w-[90%] h-[330px] sm:w-[600px] sm:h-[280px] bg-white mt-10 px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg sm:px-10 ">
        <h1 className='text-center text-[20px] font-Roboto font-bold'>You Didnt Login, if You Don't Have a Account Create & Purchase Our Products</h1>
        <p className='text-center mt-10 text-[18px] font-Roboto '>proceed to Login</p>
        <Link to="/login">
        <button className="bg-pink-600 w-[100%] font-medium hover:bg-pink-700 bg- text-white  p-2 mt-6 rounded-lg">Click here</button>
        </Link>
        
    </div>
  )
}

export default NotLoggedIn