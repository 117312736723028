import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import DataContext from '../../util/DataContext'
const ShowAllTest = ({status, item}) => {
  

  const {userData} = useContext(DataContext)
  return (
    <div>
        <div className='bg-gradient-to-r from-stone-500 to-stone-600 pr-5 sm:pl-0 pl-4 sm:pr-5 absolute left-6 top-7 justify-center rounded-lg  p-1'>
            <h1 className='sm:text-[30px] text-[20px]  text-white font-bold sm:ml-4'>{item.title}</h1>
          </div>
          
          <div className='sm:flex justify-center block'>
          <div>
          {userData.role === "admin" && 
            <Link to={`createQuiz/${item._id}`}>
              <button className='hover:bg-lime-200 lg:w-[500px] lg:mt-7 duration-300 w-[100%] hover:text-fuchsia-700 min-[500px]:mt-[25px] mx-auto text-yellow-200 h-[50px] block  bg-transparent border-solid border-2 border-lime-300  justify-end rounded-lg  sm:mt-0 mt-5 text-lg sm:m-4 m-0  font-bold '>Create Quiz</button>
            </Link>
          }
            <Link target="_blank" to={`testPage/${item._id}`}>
              <button className='hover:bg-yellow-300 lg:w-[500px] lg:mt-7 duration-300 w-[100%] hover:text-fuchsia-700 min-[500px]:mt-[25px] mx-auto text-yellow-200 h-[50px] block  bg-transparent border-solid border-2 border-yellow-300  justify-end rounded-lg  sm:mt-0 mt-5 text-lg sm:m-4 m-0  font-bold '>Start Test</button>
            </Link>
            <Link to={item.answerKey} target="_blank">
              <button className='hover:bg-white lg:w-[500px] lg:mt-7 duration-300 w-[100%] hover:text-purple-500 min-[500px]:mt-[25px] mx-auto text-white h-[50px] block  bg-transparent border-solid border-2 border-white justify-end rounded-lg  sm:mt-0 mt-5 text-lg sm:m-4 m-0  font-bold '>Download AnswerKey</button>
            </Link>
            {userData.role === "admin" && 
            <Link to={`quizResults/${item._id}`} >
              <button className='hover:bg-white lg:w-[500px] lg:mt-7 duration-300 w-[100%] hover:text-purple-500 min-[500px]:mt-[25px] mx-auto text-white h-[50px] block  bg-transparent border-solid border-2 border-white justify-end rounded-lg  sm:mt-0 mt-5 text-lg sm:m-4 m-0  font-bold '>Show Marks</button>
            </Link>
          }
            </div>
          </div>
    </div>
  )
}

export default ShowAllTest