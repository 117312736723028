import React from 'react'
import { useLoaderData } from 'react-router-dom';
import CaItem from '../components/Cards/CaItem';


const CardDetailsPage = () => {

  const cardData = useLoaderData()

  return (
    <div>
      <CaItem item = {cardData}/>
    </div>
  )
}

export const cardDetailLoader = async({params}) => {

  const {id} = params
  const response = await fetch(process.env.REACT_APP_BACKENDURL+"/ca/" + id);
    if(!response.ok){
      throw new Error("Details Not Found");
    }
    const jsonResponse = await response.json()
    return jsonResponse;
} 

export default CardDetailsPage;



  