import React from 'react'
import AddCards from '../components/AddCa/AddCards'

const CardsPage = () => {
  return (
    <div>
        <AddCards/>
    </div>
  )
}

export default CardsPage