import React, { useCallback, useEffect, useState } from 'react'
import ShowAllTest from './ShowAllTest'
import axios from 'axios'


const SelectedTest = ({data, setShowAllTests, status}) => {
    const svg1 = <svg  className="h-6 w-6"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor" >
    <path
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    d="M6 18L18 6M6 6l12 12"/>
    </svg>
   
    // const testListData = (data.testData)
    const closeHandler = () => {
      setShowAllTests(prevValue => !prevValue)
    }
    const [productData, setProductData] = useState([])
    const id = data._id
     
    const fetchData = useCallback(async () => {
      try {
          const apiUrl = process.env.REACT_APP_BACKENDURL + "/quizTestData";
          const response = await axios.get(`${apiUrl}/${id}`);
          setProductData(response.data);
      } catch (error) {
          console.log("error", error);
      }
  }, [id]); // Include `id` as a dependency if it can change

  useEffect(() => {
      if (productData.length === 0) {
          fetchData();
      }
  }, [productData, fetchData]);
    
  return (
  <> 
  <div className='flex justify-between'>
    <h1 className='text-[25px] font-bold my-4 sm:ml-4 '>{data.title}</h1>
    <button onClick={closeHandler} className='bg-pink-600 mt-5 h-8 hover:bg-pink-500 rounded-md text-white'>{svg1}</button>
  </div>
  
    {productData.map((item)=>(
      item.status === "1" && (
        <div key={item._id} className='relative mt-6 p-5 shadow-xl drop-shadow-2xl pt-20 bg-gradient-to-r from-indigo-400 to-blue-600 rounded-lg mx-auto  max:w-lg'>
          <ShowAllTest status={status} item = {item}/>
        </div>
      )
    ))}
    </>
  )
}

export default SelectedTest