import React, {useRef, useState} from 'react'
import JoditEditor from "jodit-react"
import { errorToast, successToast } from '../../util/toaster'
const TestCreate = () => {
    const [title , setTitle] = useState("")
    const [image, setImage] = useState("")
    const [description, setDescription] = useState("")
    const [price, setPrice] = useState("")
    const [drive, setDrive] = useState("")
    const editor = useRef(null)

    
    const submitHandler = (event) => {
        event.preventDefault()
        console.log(title, image, description, price, drive)
        const newTest = {
            // key:"_id",       
            // _id: Math.random().toString(),
            title: title,
            imageUrl: image,
            description: description,
            price: price,
            drive: drive
        }
       const postData =  {
        method: "POST",
        headers: {
            'Content-Type' : 'application/json'
        },
        body: JSON.stringify(newTest)

       }
       const res = fetch(process.env.REACT_APP_BACKENDURL+"/testbatch",
       postData)
       
       if(res){
        successToast("Posted Succussfully");
       }
       else{
        errorToast("Error Accured")
       }
    }
    const labelClass = 'justify-start font-medium'
    const inputClass = " font-medium border-solid active:border-lime-600 pl-2 mt-2 w-[100%] justify-stretch block border-cyan-500 border-2 rounded-md"

  return (
    <div className="relative bg-white mt-20 px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg sm:px-10">
        <form onSubmit={submitHandler}>
            <label className={labelClass}>Add Test Title</label>
            <input value={title} onChange={(e)=> setTitle(e.target.value)} className={inputClass} type="text"></input>
            <br></br>
            <label className={labelClass}>Add Image Url</label>
            <input value={image} onChange={(e)=> setImage(e.target.value)} className={inputClass} type="text"></input>
            <br></br>
            <label className={labelClass}>Add Price</label>
            <input value={price} onChange={(e)=> setPrice(e.target.value)} className={inputClass} type="text"></input>
            <br></br>
            <label className={labelClass}>Add Drive Link</label>
            <input value={drive} onChange={(e)=> setDrive(e.target.value)} className={inputClass} type="text"></input>
            <br></br>
            <label className={labelClass}>Add Description</label>
            <JoditEditor
                ref={editor}
                value={description}
                onChange={newContent => setDescription(newContent) }
            />
            <br></br>
            
            <button className="bg-pink-600 w-[100%] font-medium hover:bg-pink-700 bg- text-white  p-2 mt-6 rounded-lg">Submit</button>
        </form>
        
        </div>
  )
}

export default TestCreate