

export function getAuthToken (){
    const token = localStorage.getItem('token')
    return token 
    
}
export function removeToken(){
    const removeToken = localStorage.removeItem('token')
    return removeToken
}

