import React from 'react'
import { Link } from 'react-router-dom';

// import CaHomePage from './CaHomePage';
// import TnpscUpdates from './TnpscUpdates';
const TestBatchDesign = () => {
  return (
    <div>
        {/* <TnpscUpdates/> */}
        <div className=' sm:hidden flex justify-end pr-5 mb-10'>
        <Link to="/yourPurchases">
        <button className='text-lg  font-Rahul1 text-white mt-10 duration-300 bg-amber-600 hover:bg-amber-700 border-solid border-2 border-amber-600 p-3 h-13 rounded-lg '>Your Purchases</button>
        </Link>
        </div>
        <div className='sm:text-xl sm:mx-[10%] text-[40px] mx-5 text-sm justify-center sm:grid grid-cols-2'>
        <img className='justify-center w-[100%] flex ' alt='testbatch' src="https://i.ibb.co/SfxH5KB/test-Batch.png"></img>
        <div className='md:mt-[30px] lg:mt-[35px] sm:mt-[35px] mx-auto text-center'>
        <h2 className= ' font-Rahul1 text-blue-600 font-extrabold mx-auto font-sans text-[40px] lg:mt-[50px] lg:text-[85px] lg:leading-[100px] md:leading-[60px] mt-4 sm:text-[50px]  leading-[50px] sm:leading-[50px]'>TNPSC GR-IV Test Batch</h2>
        <h2 className='bg-clip-text text-transparent bg-gradient-to-r from-emerald-500 to-black md:text-[30px] sm:mt-[20p%] mt-5 font-extrabold lg:mt-10 text-[20px] lg:text-[40px] sm:text-4xl'>Current Affairs</h2>
        <Link to="/testbatch">
    <button className='sm:max-w-20 md:mt-5 hover:text-white mt-10 hover:bg-blue-600 lg:w-[300px] lg:mt-10 duration-300 w-[200px] min-[500px]:mt-[25px] mx-auto text-blue-600 h-[50px] block rounded-lg text-lg bg-transparent border-solid border-2 border-blue-500'>Click here</button>
    </Link>   
    </div>
    </div>
    {/* <CaHomePage/> */}
    </div>
  )
}

export default TestBatchDesign